import PremiumCalendar from 'components/premium-calendar'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import rrulePlugin from '@fullcalendar/rrule'
import scrollGridPlugin from '@fullcalendar/scrollgrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import { CalendarReferenceContext } from 'components/contexts/calendar-reference-context'
import { Option } from 'baseui/select'

import { BLACKOUT_TYPE } from 'components/constants/blackout-type'
import moment from 'moment'
import useEvents from './hooks/use-events'
import EventTile from 'components/ui/specific/EventTile'
import Blackouts from './toggles/blackouts'
import useBlackouts from './hooks/use-blackouts'
import { FacilitiesContext } from './context/facilities-context'
import { DEFAULT_LOCALE_STRING_LANGUAGE } from '../../constants/default-locale-string-language'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { subscribeToRTNotifications } from '../../rt-notifications/appointments-channel'
import { RefreshSearchContext } from '../../../lib/cyber-components/search/search'
import InstantDatePicker from './toggles/instant-date-picker'
import CalendarPopover from './calendar-popover'
import { useTranslation } from 'react-i18next'

export const SATURDAY_ID = 6
export const WEEK_KEY = 'week'
export const DAY_KEY = 'day'

export const getDateFilterFormat = (date: Date, weekMode?: boolean) => {
  if (weekMode) {
    return [
      moment(date).startOf(WEEK_KEY).toDate(),
      moment(date).endOf(WEEK_KEY).day(SATURDAY_ID).endOf(DAY_KEY).toDate()
    ]
  }

  return [
    moment(date.toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE)).startOf(DAY_KEY).toDate(),
    moment(date.toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE)).endOf(DAY_KEY).toDate()
  ]
}

const NonConnectedCalendarHits = ({
  hits,
  selectedEvent,
  setSelectedEvent,
  timeRange,
  selectedFacilities,
  selectedDate,
  setSelectedDate,
  setNumberOfHits
}) => {
  const {
    calendarReference,
    setDateInCalendarFilter,
    setCacheDate,
    cacheDate,
    weekMode,
    setWeekMode
  } = useContext(CalendarReferenceContext)
  const { facilities, slotDuration } = useContext(FacilitiesContext)
  const events = useEvents(hits, facilities)
  const blackouts = useBlackouts({ selectedEvent, selectedFacilities, weekMode })
  const [prevModeDate, setPrevModeDate] = useState(new Date())
  const { currentUser } = useContext(CurrentUserContext)
  const { t, i18n } = useTranslation()

  const [showDatepicker, setShowDatepicker] = useState(false)

  const setDateAndCache = (newDate: Date) => {
    setSelectedDate(newDate)
    const dateFormatted = getDateFilterFormat(newDate, weekMode)
    setDateInCalendarFilter(dateFormatted)
    setCacheDate({
      ...cacheDate,
      [currentUser.shipperId]: {
        ...((cacheDate && cacheDate[currentUser.shipperId]) || {}),
        [currentUser.id]: dateFormatted
      }
    })
  }

  const handleCustomPrevClick = () => {
    const newDate = selectedDate
    newDate.setDate(newDate.getDate() - (weekMode ? 7 : 1))
    setDateAndCache(newDate)
  }

  const handleCustomTodayClick = () => {
    setDateAndCache(new Date())
  }

  const handleCustomNextClick = () => {
    const newDate = selectedDate
    newDate.setDate(newDate.getDate() + (weekMode ? 7 : 1))
    setDateAndCache(newDate)
  }

  const datepicker = () => {
    setShowDatepicker(!showDatepicker)
  }

  const { refresh } = useContext(RefreshSearchContext)

  const selectedDateRef = useRef(selectedDate)

  useEffect(() => {
    setDateAndCache(selectedDate)
  }, [weekMode])

  useEffect(() => {
    function filterHitsByRange(hits, timeRange) {
      const minTimeHours = parseInt(timeRange.minTime[0].label.split(':')[0], 10)
      const minTimeMinutes = parseInt(timeRange.minTime[0].label.split(':')[1], 10)
      const maxTimeHours = parseInt(timeRange.maxTime[0].label.split(':')[0], 10)
      const maxTimeMinutes = parseInt(timeRange.maxTime[0].label.split(':')[1], 10)

      const filteredHits = hits.filter(hit => {
        const arrivalTime = new Date(hit.arrival_time * 1000)
        const arrivalHours = arrivalTime.getHours()
        const arrivalMinutes = arrivalTime.getMinutes()

        return (
          (arrivalHours > minTimeHours ||
            (arrivalHours === minTimeHours && arrivalMinutes >= minTimeMinutes)) &&
          (arrivalHours < maxTimeHours ||
            (arrivalHours === maxTimeHours && arrivalMinutes < maxTimeMinutes))
        )
      })

      return filteredHits
    }
    selectedDateRef.current = selectedDate
    if (timeRange.maxTime[0].label !== '24:00' || timeRange.minTime[0].label !== '00:00') {
      const filteredHits = filterHitsByRange(hits, timeRange)
      setNumberOfHits(filteredHits.length)
    } else {
      setNumberOfHits(hits.length)
    }
  }, [selectedDate, timeRange, hits, setNumberOfHits])

  useEffect(() => {
    if (currentUser?.shipperId) {
      subscribeToRTNotifications(currentUser?.shipperId, refresh, selectedDateRef)
    }
  }, [currentUser])

  useEffect(() => {
    const dayModeBtn = document.querySelector('.fc-customResourceTimeGridDay-button') as HTMLElement
    const weekModeBtn = document.querySelector(
      '.fc-customResourceTimeGridWeek-button'
    ) as HTMLElement
    if (weekMode) {
      dayModeBtn.style.backgroundColor = 'unset'
      weekModeBtn.style.backgroundColor = '#dbe6ff'
      calendarReference.current.getApi().changeView('resourceTimeGridWeek')
    } else {
      dayModeBtn.style.backgroundColor = '#dbe6ff'
      weekModeBtn.style.backgroundColor = 'unset'
      calendarReference.current.getApi().changeView('resourceTimeGridDay')
    }
  }, [weekMode, calendarReference])

  return (
    <div className={weekMode ? 'week-calendar' : 'day-calendar'}>
      <CalendarPopover isOpen={showDatepicker} togglePopover={setShowDatepicker}>
        <InstantDatePicker attribute="arrival_time" />
      </CalendarPopover>
      <PremiumCalendar
        height="74vh"
        innerRef={calendarReference}
        plugins={[
          rrulePlugin,
          timeGridPlugin,
          interactionPlugin,
          momentPlugin,
          scrollGridPlugin,
          resourceTimeGridPlugin
        ]}
        slotDuration={slotDuration}
        slotLabelFormat={
          // `hourCycle` is pretty new, not in the TS defs. Likewise it dosen't work in Safari.
          // If we use `hour12` instead of `hourCycle`, then hour12 takes priority. This means that we would see
          // `00:00` instead of `24:00` at the beginning of the day. As such, we are deciding to not worry about Safari
          // and are ok that it will be formatted with AM/PM times, in favor of not screwing up `00:00`.
          {
            hourCycle: 'h23',
            hour: '2-digit',
            minute: '2-digit'
          } as any
        }
        initialView="resourceTimeGridDay"
        customButtons={{
          customPrevButton: {
            icon: 'chevron-left',
            click: handleCustomPrevClick
          },
          customTodayButton: {
            text: weekMode
              ? t('Scheduler.Appointments.Table.CurrentWeek.Text')
              : t('Scheduler.Appointments.Table.Today.Text'),
            click: handleCustomTodayClick
          },
          customNextButton: {
            icon: 'chevron-right',
            click: handleCustomNextClick
          },
          datepicker: {
            text: '',
            click: datepicker
          },
          customResourceTimeGridDay: {
            click: () => {
              if (weekMode) {
                setWeekMode(false)
                setPrevModeDate(selectedDate)
                const newDate = prevModeDate
                setDateAndCache(newDate)
                calendarReference.current.getApi().changeView('resourceTimeGridDay')
              }
            },
            text: t('Scheduler.Appointments.Table.Day.Text')
          },
          customResourceTimeGridWeek: {
            click: () => {
              if (!weekMode) {
                setWeekMode(true)
                setPrevModeDate(selectedDate)
                calendarReference.current.getApi().changeView('resourceTimeGridWeek')
              }
            },
            text: t('Scheduler.Appointments.Table.Week.Text')
          }
        }}
        headerToolbar={{
          left: 'title datepicker customPrevButton customTodayButton customNextButton',
          center: '',
          right: 'customResourceTimeGridDay,customResourceTimeGridWeek'
        }}
        events={[...events, ...blackouts]}
        // This pattern (wrapping component in a function) was reccomended by the library maintainer
        // https://github.com/fullcalendar/fullcalendar/issues/5523
        eventContent={({ event }) => {
          return <EventTile event={event} />
        }}
        eventClick={({ event }) => {
          const selectedEvent: SelectedEvent = {
            id: event.id,
            blackout: event._def?.extendedProps?.type === BLACKOUT_TYPE
          }
          setSelectedEvent(selectedEvent)
          return false
        }}
        dateClick={({ date, resource }) => {
          setSelectedEvent({ facilityId: resource._resource.id, arrivalTime: date })
        }}
        allDayContent={({ view }) => (
          <Blackouts
            blackouts={blackouts}
            setSelectedEvent={setSelectedEvent}
            selectedFacilities={selectedFacilities}
            date={view.currentStart}
          />
        )}
        titleFormat={{
          month: 'short',
          weekday: 'short',
          day: 'numeric'
        }}
        nowIndicator
        firstDay={0}
        slotMinTime={`${timeRange && timeRange.minTime[0].label}:00`}
        slotMaxTime={`${timeRange && timeRange.maxTime[0].label}:00`}
        resourceOrder="position"
        resources={selectedFacilities.map((facility: Option, idx: number) => ({
          id: facility.id,
          title: facility.name,
          position: idx
        }))}
        dayHeaderContent={({ date }) => {
          return {
            html: `${moment(date).locale(i18n.language).format('ddd')} <h6 class="date">${moment(
              date
            ).format('D')}</h6>`
          }
        }}
        dayHeaderFormat={{ day: 'numeric', weekday: 'short' }}
        navLinks
        navLinkDayClick={day => {
          if (weekMode) {
            setWeekMode(false)
            setDateAndCache(day)
            calendarReference.current.getApi().changeView('resourceTimeGridDay')
          }
        }}
        slotEventOverlap={false}
        dayMinWidth={80}
        datesAboveResources
      />
    </div>
  )
}

// The 'as any' here is so I can pass params to this connected component that are not in it's signature
const CalendarHits = connectHits(NonConnectedCalendarHits) as any

export default CalendarHits
