import React, { ChangeEvent, Dispatch, useState } from 'react'
import { Appointment } from '../appointments/types'
import regexValidations from '../utils/regex-validations'
import { useDebounce } from 'react-use'

import FormControl from 'components/ui/generic/FormControl'
import Textarea from 'components/ui/generic/Textarea'

interface OthersToNotifyProps {
  appointment: Appointment
  setAppointment: Dispatch<Appointment>
  setAllOthersToNotifyAreValid: Dispatch<boolean>
}

const OthersToNotify = ({
  appointment,
  setAppointment,
  setAllOthersToNotifyAreValid
}: OthersToNotifyProps) => {
  const [emails, setEmails] = useState('')

  const handleEmailChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    setEmails(target.value)
  }

  const validateEmails = () => {
    if (emails) {
      const emailList = emails.split(/[;,]/).map(email => email.trim())

      const allValid = emailList.every(email => regexValidations.email.test(email))

      setAllOthersToNotifyAreValid(allValid)
      setAppointment({
        ...appointment,
        othersToNotify: emails
      })
    } else {
      setAllOthersToNotifyAreValid(true)
    }
  }

  useDebounce(() => validateEmails(), 200, [emails])

  return (
    <FormControl label="Others to notify" caption="Enter emails separated by ',' or ';'">
      <Textarea
        value={emails}
        onChange={handleEmailChange}
        placeholder="Enter emails separated by ';' or ','"
      />
    </FormControl>
  )
}

export default OthersToNotify
