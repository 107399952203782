import React, { useState, useContext } from 'react'
import { ProgressSteps } from 'baseui/progress-steps'
import { Block } from 'baseui/block'
import { HeadingSmall, ParagraphSmall } from 'baseui/typography'
import ReactHtmlParser from 'react-html-parser'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import Recaptcha from 'components/shared/recaptcha'
import { useLocalStorage } from 'components/shared/hooks/use-local-storage'
import moment from 'moment'
import { useStyletron } from 'baseui'
import { CaretLeft } from '@phosphor-icons/react'
import VelosticsDemo from 'components/shared/velostics_demo'
import { trim } from 'lodash'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { appointmentService } from 'components/services/appointment.service'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Step from 'components/ui/generic/Step'
import Button from 'components/ui/specific/SecondaryButton'
import Header from 'components/ui/generic/Header'
import { fancyToast } from '../utils'
import AdditionalInformation from './book/additional-information'
import PurchaseOrderStep from './steps/purchase-order-step'
import DateAndTimeStep from './steps/date-and-time-step'
import TimeFormatter from '../utils/time-formatter'
import FacilityEmailConfirmation from './facility-email-confirmation'
import UserOnboardingModal from './user-onboarding-modal'
import AppointmentFeedback from '../shared/appointment-feedback'
import { CurrentUserContext } from '../homepage/current-user-context'
import useSizing from '../shared/hooks/use-sizing'
import { BackIcon } from './FacilityBook.styled'
import OthersToNotify from './OthersToNotify'
import ErrorMessageButton from '../shared/error-message-button'

export const PurchaseOrderLabelsContext = React.createContext({
  purchaseOrderLabels: null
})

const FacilityBook = props => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location?.search)
  const orderNumber = queryParams.get('orderNumber')

  const [, theme] = useStyletron()
  const { currentUser } = useContext(CurrentUserContext)
  const [facility, setFacility] = useState<any>({})
  const [allOthersToNotifyAreValid, setAllOthersToNotifyAreValid] = useState<boolean>(true)
  const [progress, setProgress] = React.useState(1)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [purchaseOrders, setPurchaseOrders] = React.useState([{ identifier: orderNumber || '' }])
  const { customBreakPoints } = useSizing()
  const [appointment, setAppointment] = useState<any>({})
  const {
    answers,
    questions,
    actions: { resetTemporalFilesState, updateFileUploadState }
  } = useCQStateValue()

  const { t } = useTranslation()

  const createAppointment = async () => {
    setLoading(true)

    const slotCount =
      (facility && facility.appointmentType && facility.appointmentType.slotCount) || 1
    const body = {
      appointment: {
        ...appointment,
        ...(answers && { answersAttributes: answers }),
        ...(questions && { questions }),
        facilityId: facility.id,
        purchaseOrdersAttributes: appointment.purchaseOrdersAttributes.map(
          (order: { identifier: string }) => ({
            identifier: trim(order.identifier)
          })
        ),
        slotCount,
        schedulerMode: currentUser.schedulerMode,
        othersToNotify: appointment?.othersToNotify
          ?.split(/[;,]/)
          .map((email: string) => email.trim())
      }
    }
    const [json, status] = await appointmentService.create(body)

    setLoading(false)
    if (status === 201) {
      resetTemporalFilesState()
      setAppointment({ ...appointment, id: json.id, confirmationId: json.confirmationId })
      setProgress(5)
    } else if (status === 409) {
      fancyToast(json, status)
      setProgress(3)
    } else {
      fancyToast(json, status)
    }
  }

  const appointmentTimeMessage = () => {
    if (
      facility.firstComeFirstServeOpenTime &&
      facility.firstComeFirstServeCloseTime &&
      appointment.quantity <= facility.maxLtlQuantity
    ) {
      const timeRange = new TimeFormatter('shortTime').formatRange(
        facility.firstComeFirstServeOpenTime,
        facility.firstComeFirstServeCloseTime,
        facility.timeZone
      )
      return `from ${timeRange}`
    }
    const formattedTime = new TimeFormatter('shortTime').format(
      moment(new Date(appointment.arrivalTime))
        .add(appointment.quantity * 5, 'minutes')
        .toDate(),
      facility.timeZone
    )

    return `at ${formattedTime}`
  }

  const formatPurchaseOrders = () => {
    return (
      appointment &&
      appointment.purchaseOrdersAttributes &&
      appointment.purchaseOrdersAttributes
        .map(({ identifier }) => {
          return identifier
        })
        .join(', ')
    )
  }

  const goBackOneStep = () => {
    if (Math.min(1, progress) <= 1) {
      updateFileUploadState(false)
      resetTemporalFilesState()
    }
    setProgress(Math.min(1, progress))
  }

  return (
    <>
      {currentUser ? (
        <>
          <UserOnboardingModal />
          <Header
            title={`${facility?.shipperName || ''} ${t(
              'Scheduler.OpenScheduling.Header.Appointment.Text'
            )} ${t('Scheduler.OpenScheduling.Header.SchedulingPortal.Text')}`}
          />
          <Block maxWidth="720px">
            <ProgressSteps
              current={progress}
              overrides={{
                Root: {
                  style: {
                    width: '100%',
                    transform: customBreakPoints?.mdMax ? 'translateX(-25px)' : ''
                  }
                }
              }}>
              <Step title={t('Scheduler.OpenScheduling.Steps.TermsOfService.Header.Text')} isActive>
                <FlexGrid flexGridRowGap="scale200">
                  <FlexGridItem>
                    <ParagraphSmall>
                      {facility.id
                        ? facility.note
                          ? ReactHtmlParser(facility.note)
                          : t(
                              'Scheduler.OpenScheduling.Steps.TermsOfService.SubTitles.NotRequireSpecialTerms.Text'
                            )
                        : t(
                            'Scheduler.OpenScheduling.Steps.TermsOfService.SubTitles.PleaseSelectFacility.Text'
                          )}
                    </ParagraphSmall>
                  </FlexGridItem>
                </FlexGrid>
              </Step>

              <Step title={t('Scheduler.OpenScheduling.Steps.OrderInformation.Header.Text')}>
                <PurchaseOrderLabelsContext.Provider
                  value={{
                    purchaseOrderLabels:
                      facility &&
                      facility.appointmentPreference &&
                      facility.appointmentPreference.purchaseOrderLabels
                  }}>
                  <PurchaseOrderStep
                    {...{
                      facility,
                      setFacility,
                      appointment,
                      setAppointment,
                      setProgress,
                      purchaseOrders,
                      setPurchaseOrders
                    }}
                  />
                </PurchaseOrderLabelsContext.Provider>
              </Step>
              <Step title={t('Scheduler.OpenScheduling.Steps.AdditionalInformation.Header.Text')}>
                <FlexGrid
                  flexGridColumnCount={customBreakPoints.mdMax ? 1 : 3}
                  flexGridColumnGap="scale400">
                  <FlexGridItem>
                    <AdditionalInformation
                      afterSubmit={() => {
                        setProgress(3)
                      }}
                      finishButtonLabel={t(
                        'Scheduler.OpenScheduling.Steps.AdditionalInformation.FinishButton.Text'
                      )}
                      appointment={appointment}
                      setAppointment={setAppointment}
                      appointmentType={appointment.appointmentType}
                      userType={(currentUser && currentUser.userType) || 'broker'}
                    />
                  </FlexGridItem>
                </FlexGrid>
              </Step>
              <Step title={t('Scheduler.OpenScheduling.Steps.DateTime.Header.Text')}>
                <DateAndTimeStep
                  {...{
                    facility,
                    appointment,
                    setAppointment,
                    purchaseOrders
                  }}
                  onTimeSelect={() => setProgress(4)}
                />
              </Step>
              <Step title={t('Scheduler.OpenScheduling.Steps.Review.Header.Text')}>
                <OthersToNotify
                  appointment={appointment}
                  setAppointment={setAppointment}
                  setAllOthersToNotifyAreValid={setAllOthersToNotifyAreValid}
                />
                <ParagraphSmall>
                  {t(
                    'Scheduler.OpenScheduling.Steps.Review.SubTitles.SchedulingAppointmentFor.Text'
                  )}{' '}
                  {formatPurchaseOrders()}{' '}
                  {t('Scheduler.OpenScheduling.Steps.Review.SubTitles.On.Text')}
                  {new TimeFormatter('shortDate').format(
                    appointment.arrivalTime,
                    facility.timeZone
                  )}{' '}
                  {appointmentTimeMessage()}
                </ParagraphSmall>
                <ErrorMessageButton
                  errors={[
                    {
                      label: t(
                        'Scheduler.OpenScheduling.Steps.Review.Validations.MailsMustBeValid.Text'
                      ),
                      status: allOthersToNotifyAreValid
                    }
                  ]}
                  buttonProps={{ onClick: createAppointment, isLoading: loading }}
                  label={t('Scheduler.OpenScheduling.Steps.Review.Buttons.Submit.Text')}
                />
              </Step>
              <Step title={t('Scheduler.OpenScheduling.Steps.Finish.Header.Text')}>
                <HeadingSmall marginBottom="12px">
                  {t('Scheduler.OpenScheduling.Steps.Finish.SubTitles.YouAreAllSet.Text')}
                </HeadingSmall>
                <ParagraphSmall>
                  {t('Scheduler.OpenScheduling.Steps.Finish.SubTitles.YourAppointment.Text')}{' '}
                  <b>{appointment.confirmationId}</b>{' '}
                  {t('Scheduler.OpenScheduling.Steps.Finish.SubTitles.HasBeenScheduled.Text')}
                </ParagraphSmall>
                <FlexGrid width="100%" flexGridRowGap="scale800">
                  <FlexGridItem>
                    <AppointmentFeedback appointmentId={appointment.id} />
                  </FlexGridItem>
                  <FlexGridItem>
                    <Button
                      onClick={() => {
                        window.location.reload()
                      }}>
                      {t(
                        'Scheduler.OpenScheduling.Steps.Finish.Buttons.ScheduleAnotherAppointment.Text'
                      )}
                    </Button>
                  </FlexGridItem>
                  <FlexGridItem>
                    <VelosticsDemo />
                  </FlexGridItem>
                </FlexGrid>
              </Step>
            </ProgressSteps>
          </Block>
        </>
      ) : (
        <FacilityEmailConfirmation {...props} facility={facility} />
      )}
    </>
  )
}
export default FacilityBook
