import React, { ReactElement, useContext } from 'react'
import { AppNavBar, NavItemT } from 'baseui/app-nav-bar'
import {
  ClipboardText,
  ListBullets,
  Receipt,
  ChartLine,
  Warehouse,
  CalendarBlank,
  CalendarPlus,
  CalendarCheck,
  GearSix,
  User,
  SignOut,
  Door,
  Info
} from '@phosphor-icons/react'
import { NavLink } from 'react-router-dom'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import Logo from 'components/logo'
import { authService } from 'components/services/auth.service'
import { StatefulTooltip } from 'baseui/tooltip'
import { Block } from 'baseui/block'
import { CurrentUserContext } from '../../homepage/current-user-context'
import NavbarLinks from './MainNavLinks'

const NavIcon = ({ path, currentUser }) => {
  const pathArray = path.split('/')
  const secondLevelLocation = pathArray[1]

  if (!currentUser) {
    switch (path) {
      case '/':
        return <SignOut />
      case '/openscheduling':
        return <CalendarPlus />
      case '/openscheduling/update':
        return <CalendarCheck />
      default:
        return null
    }
  }
  if (currentUser.userType === 'scheduler') {
    switch (path) {
      case '/':
        return <CalendarPlus />
      case '/appointments':
        return <CalendarCheck />
      default:
        return <User />
    }
  }

  switch (secondLevelLocation) {
    case '':
      return <ClipboardText />
    case 'appointments':
      return <ListBullets />
    case 'dock_assignments':
      return <Door />
    case 'analytics':
      return <ChartLine />
    case 'facilities':
      return <Warehouse />
    case 'orders':
      return <Receipt />
    case 'settings':
      return <GearSix />
    case 'user':
      return <User />
    case 'openscheduling':
      return <CalendarBlank />
    case 'logout':
      return null
    default:
      return null
  }
}

const NavbarTooltip = ({ message }) => (
  <Block minWidth="240px" maxWidth="280px" display="flex" gridColumnGap="12px">
    <Info size={24} />
    <span>{message}</span>
  </Block>
)

const Navigation = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation()
  const [css, theme] = useStyletron()
  const { setCurrentUser } = useContext(CurrentUserContext)

  const logout = () => {
    setCurrentUser(null)
    authService.logout()
  }

  return (
    <AppNavBar
      title={
        <Logo
          ids={currentUser?.shipperId ? [currentUser?.shipperId] : currentUser?.shipperIds || []}
        />
      }
      mainItems={NavbarLinks(currentUser)}
      mapItemToNode={(item: NavItemT & { tooltipMessage: string; disable?: boolean }) => {
        return item?.tooltipMessage && item?.disable ? (
          <StatefulTooltip
            triggerType="hover"
            content={<NavbarTooltip message={item.tooltipMessage} />}
            placement="auto"
            overrides={{
              Inner: {
                style: ({ $theme }) => ({
                  paddingTop: $theme.sizing.scale500,
                  paddingBottom: $theme.sizing.scale500,
                  paddingLeft: $theme.sizing.scale500,
                  paddingRight: $theme.sizing.scale500,
                  backgroundColor: $theme.colors.backgroundPrimary,
                  color: $theme.colors.contentPrimary,
                  fontSize: '13px'
                })
              },
              Body: {
                style: ({ $theme }) => ({
                  outline: `1px ${$theme.borders.borderColor} solid`,
                  backgroundColor: $theme.colors.backgroundPrimary
                })
              }
            }}>
            <NavLink
              className={css({
                textDecoration: 'none',
                height: '56px',
                lineHeight: '56px',
                opacity: '0.4',
                ':hover': {
                  color: 'black'
                }
              })}
              to="">
              <span
                className={css({
                  marginRight: theme.sizing.scale300,
                  verticalAlign: 'middle'
                })}>
                <NavIcon path={item.info.itemId} currentUser={currentUser} />
              </span>
              {item.label}
            </NavLink>
          </StatefulTooltip>
        ) : (
          <NavLink
            className={css({
              color: 'inherit',
              textDecoration: 'none',
              height: '56px',
              lineHeight: '56px'
            })}
            to={item.info?.itemId || ''}>
            <span
              className={css({
                marginRight: theme.sizing.scale300,
                verticalAlign: 'middle'
              })}>
              <NavIcon path={item.info.itemId} currentUser={currentUser} />
            </span>
            {item.label}
          </NavLink>
        )
      }}
      username={currentUser?.name || ''}
      usernameSubtitle={currentUser?.emailAddress || ''}
      userItems={
        currentUser
          ? [{ label: t('Logout.LogoutForm.Text'), info: { itemId: '/logout' }, icon: SignOut }]
          : []
      }
      onUserItemSelect={item => (item.label === t('Logout.LogoutForm.Text') ? logout() : null)}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            boxShadow: `0 1px 0 ${$theme.colors.gray}`,
            backgroundColor: $theme.colors.backgroundSecondary,
            paddingLeft: 0,
            zIndex: 3
          })
        },
        Spacing: {
          style: ({ $theme }) => ({
            paddingTop: $theme.sizing.scale500,
            paddingBottom: $theme.sizing.scale500,
            [$theme.mediaQuery.medium]: {
              paddingTop: $theme.sizing.scale500,
              paddingBottom: $theme.sizing.scale500
            }
          })
        },
        MainMenuItem: {
          style: ({ $active, $theme, $hover }) => ({
            fontSize: $theme.typography.LabelXSmall.fontSize,
            fontFamily: 'Rubik',
            color: $active || $hover ? $theme.colors.accent : $theme.colors.primary,
            textDecoration: 'none',
            borderBottomColor: $active ? $theme.colors.accent : 'transparent',
            [$theme.mediaQuery.xlarge]: {
              fontSize: '13px'
            }
          })
        },
        UserMenuProfileListItem: {
          style: ({ $theme }) => ({ fontSize: $theme.typography.LabelXSmall.fontSize })
        },
        UserProfileInfoContainer: {
          style: ({ $theme }) => ({
            overflow: 'hidden',
            paddingRight: $theme.sizing.scale300
          })
        }
      }}
    />
  )
}

const Navbar = (): ReactElement => {
  return <Navigation />
}

export default Navbar
