import { AppointmentDuration } from 'components/models/AppointmentDuration'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { useCallback, useEffect, useState } from 'react'
import { FacilityOption } from './use-default-facilities'

export const DEFAULT_SLOT_DURATIONS = {
  [900]: '00:15',
  [1800]: '00:30',
  [2700]: '00:45',
  [3600]: '01:00'
}

export const formatByAppointmentType = (appointmentDurations: AppointmentDuration[]) => {
  const hashedByAppointmentType = {}

  const appointmetTypesIds = appointmentDurations.map(
    appointmentDuration => appointmentDuration.appointmentTypesId
  )

  const uniqueAppointmentTypesIds = new Set(appointmetTypesIds)

  uniqueAppointmentTypesIds.forEach(typeId => {
    hashedByAppointmentType[typeId] = formatByEquipmentType(typeId, appointmentDurations)
  })

  return hashedByAppointmentType
}

const formatByEquipmentType = (
  appointmentTypesId: string,
  appointmentDurations: AppointmentDuration[]
) => {
  const hashedByEquipmentType = {}

  const appointmentDurationByTypeId = appointmentDurations.filter(
    appointmentDuration => appointmentDuration.appointmentTypesId === appointmentTypesId
  )

  for (const appointmentDuration of appointmentDurationByTypeId) {
    hashedByEquipmentType[appointmentDuration.equipmentType.id] = appointmentDuration
  }

  return hashedByEquipmentType
}

const useFacilityInfo = (facilityOptions: FacilityOption[]) => {
  const [facilities, setFacilities] = useState({})
  const [slotDuration, setSlotDuration] = useState('00:30')

  useEffect(() => {
    if (facilityOptions.length > 0) {
      const path = facilityOptions
        .map(option => option.id)
        .reduce((str, id, i) => str + (i > 0 ? '&' : '') + 'id[]' + '=' + id, '/facilities.json/?')

      authenticatedFetch({
        path: path
      }).then(([json, status]) => {
        if (status === 200) {
          setFacilities(formatFacilities(json))
          setSlotDuration(getSlotDuration(json))
        }
      })
    }
  }, [facilityOptions])

  const formatFacilities = useCallback((facilities: any[]) => {
    const formattedFacilities = {}

    for (const facility of facilities) {
      formattedFacilities[facility.id] = {
        ...facility,
        appointmentDurationsHash: formatByAppointmentType(
          facility.appointmentPreference?.appointmentDurationsAttributes
        )
      }
    }

    return formattedFacilities
  }, [])

  const getSlotDuration = useCallback((facilities: any[]) => {
    const durations = facilities.map(facility => facility.appointmentDuration || 1800)
    const lowestDuration = Math.min(...durations)
    return DEFAULT_SLOT_DURATIONS[lowestDuration] || '00:30'
  }, [])

  return { facilities, slotDuration }
}

export default useFacilityInfo
