import { styled } from 'baseui'

export const OpenSchedulingCheckBox = styled('div', {
  position: 'absolute',
  top: '16px',
  right: 0
})

export const FooterRow = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  fontSize: $theme.typography.LabelSmall.fontSize
}))
